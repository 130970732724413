import type { OeComponentBase } from "./OeComponentBase";
import { type ReactNode } from "react";
import type { OceanExplorerTheme } from "theme";
import { Box, Divider, Heading, useThemeUI } from "theme-ui";

interface OePanelProps extends OeComponentBase {
  children?: ReactNode;
  heading: string;
}
export function OePanel(props: OePanelProps) {
  // Grab the theme
  const themeContext = useThemeUI();
  const theme = themeContext.theme as OceanExplorerTheme;
  if (Object.getOwnPropertyNames(theme).length <= 0) {
    // Theme not initialized yet.
    return <></>;
  }

  return (
    <Box variant="boxes.sectionRounded" sx={{ paddingTop: 0, ...props.sx }}>
      <Heading as="h3" variant={"styles.h3"} sx={theme.panels.primary.header}>
        {props.heading}
      </Heading>
      <Divider />
      <Box sx={theme.panels.primary.body}>{props.children}</Box>
    </Box>
  );
}
