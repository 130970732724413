import { OeIcon } from "./OeIcon";
import type { OeComponentBase } from "./OeComponentBase";
import { useState, type ReactNode } from "react";
import type { OceanExplorerTheme } from "theme";
import { Box, Divider, Heading, useThemeUI, Flex } from "theme-ui";

export interface OeInteractivePanelProps extends OeComponentBase {
  children?: ReactNode;
  heading: string;
  onClick?: () => void;
}
export function OeInteractivePanel(props: OeInteractivePanelProps) {
  const { onClick, ...restProps } = props;
  const [isOpen, setIsOpen] = useState(false);
  // Grab the theme
  const themeContext = useThemeUI();
  const theme = themeContext.theme as OceanExplorerTheme;
  if (Object.getOwnPropertyNames(theme).length <= 0) {
    // Theme not initialized yet.
    return <></>;
  }

  switch (isOpen) {
    case true:
      return (
        <Box
          variant="boxes.sectionRounded"
          sx={{
            paddingTop: 0,
            ...props.sx,
            position: "relative",
            marginBottom: "20px",
          }}
        >
          <Box
            onClick={() => setIsOpen(false)}
            sx={{
              cursor: "pointer",
              top: 0,
              left: 0,
              right: 0,
              bottom: "0%",
              zIndex: 1,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Heading
              as="h3"
              variant={"styles.h3"}
              sx={theme.panels.primary.header}
            >
              {props.heading}
            </Heading>
            <Box sx={{ paddingTop: "18px", paddingRight: "20px" }}>
              <OeIcon iconName="upsidedown-arrow" />
            </Box>
          </Box>
          <Divider />
          <Box sx={theme.panels.primary.body}>{props.children}</Box>
        </Box>
      );

    case false:
      return (
        <Box
          variant="boxes.sectionRounded"
          sx={{
            paddingTop: 0,
            ...props.sx,
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "20px",
          }}
          onClick={() => setIsOpen(true)}
          style={{ cursor: "pointer" }}
        >
          <Heading
            as="h3"
            variant={"styles.h3"}
            sx={theme.panels.primary.header}
          >
            {props.heading}
          </Heading>
          <Box sx={{ paddingTop: "25px", paddingRight: "20px" }}>
            <OeIcon iconName="arrow" />
          </Box>
        </Box>
      );
    default:
      return <></>;
  }
}
