export const DatePickerStyle = (theme: any) => {
  const datePickerStyles = `

    /* whole calendar styling eg. background, text */
    .react-datepicker__header,
    .react-datepicker {
      background-color: ${theme.colors.secondary} !important;
      font-family: ${theme.fonts.body} !important;
      font-size: 16px
      font-weight: normal;
      color: ${theme.colors.primary};
    }

    .react-datepicker__day-name {
      display: none;
      color: ${theme.colors.primary};
    }

    /* heading text */
    .react-datepicker,
    .react-datepicker__month-container,
    .react-datepicker__day,
    .react-datepicker__day-name   {
      border: none;
      font-weight: normal;
      color: ${theme.colors.primary};
    }

    .react-datepicker__current-month{
        font-family: ${theme.fonts.body} !important;
        font-weight: normal;
        color: ${theme.colors.primary};
    }
    .react-datepicker__month-container,
    .react-datepicker__year-container,
    .react-datepicker__header {
      border-bottom: none;
      padding-bottom: 16px;
      padding-left: 16px;
      padding-right: 16px;
      font-weight: normal;
      color: ${theme.colors.primary};
    }

    /* selected range */
    .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--selected,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__year-text--in-range,
    .react-datepicker__day--in-selecting-range {
      border-radius: 0% !important;
      color: ${theme.colors.primary};
      background-color: #E9E6DC /* TODO: switch this back to theme.colors when we have correct colour in theme */
    }

    /* selected start and end dates */
    .react-datepicker__day--range-end,
    .react-datepicker__day--range-start,
    .react-datepicker__month--range-end,
    .react-datepicker__month--range-start,
    .react-datepicker__year--range-end,
    .react-datepicker__year--range-start {
      border-radius: 50% !important;
      color: ${theme.colors.secondary};
      background-color: ${theme.colors.primary};
    }

    /* hover in selected range */
    .react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover,
    .react-datepicker__month-text--selected:hover,
    .react-datepicker__month-text--in-selecting-range:hover,
    .react-datepicker__month-text--in-range:hover,
    .react-datepicker__quarter-text--selected:hover,
    .react-datepicker__quarter-text--in-selecting-range:hover,
    .react-datepicker__quarter-text--in-range:hover,
    .react-datepicker__year-text--selected:hover,
    .react-datepicker__year-text--in-selecting-range:hover,
    .react-datepicker__year-text--in-range:hover {
      background-color: ${theme.colors.primary};
      color: ${theme.colors.secondary};
    }

    /* dates outside of min-max range */
    .react-datepicker__day--disabled,
    .react-datepicker__month-text--disabled,
    .react-datepicker__quarter-text--disabled,
    .react-datepicker__year-text--disabled {
        color: ${theme.colors.muted};
    }

    /* hover outside of selected range */
    .react-datepicker__day:hover,
    .react-datepicker__month-text:hover,
    .react-datepicker__quarter-text:hover,
    .react-datepicker__year-text:hover {
        background-color: ${theme.colors.primary};
        color: ${theme.colors.secondary};
    }

    /* selecting (start date picked but end date not confirmed) */
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__year-text--in-selecting-range {
      color: ${theme.colors.primary};
      background-color: #E9E6DC !important; /* TODO: switch this back to theme.colors when we have correct colour in theme */
    }}`;
  return <style>{datePickerStyles}</style>;
};

export default DatePickerStyle;
