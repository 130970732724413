import { useState } from "react";
import * as RDP from "react-datepicker";
import "react-datepicker/dist/react-datepicker.min.css";
import type { OceanExplorerTheme } from "theme";
import type { ThemeUIStyleObject } from "theme-ui";
import { Box, Heading, Label, useThemeUI } from "theme-ui";
import DatePickerStyle from "./OeCalendarStyle";
import type { OeComponentBase } from "./OeComponentBase";

export interface OeCalendarProps extends OeComponentBase {
  heading: string;
  startDate?: Date | undefined;
  endDate?: Date | undefined;
  minDate?: Date | undefined;
  maxDate?: Date | undefined;
  onChange?: ({
    startDate,
    endDate,
    formattedDate,
  }: {
    startDate?: Date;
    endDate?: Date;
    formattedDate?: string;
  }) => void;
}

/* Why we are doing it like this instead of just calling ReactDatePicker?*/
/* A type error in @types/react-datepicker which breaks the page whenever it tries to render a ReactDatePicker component.
The best workaround is to do a namespace import then perform a series of null-checks to see whether a ReactDatePicker of
any type exists and assign it to the below const.
Potential TODO: As of checking on 10.5.24 a type fix PR was made on the main repo so hopefully we can switch this once a
new version of datepicker comes out (newer than v6.9.0)*/

const ReactDatePicker = (((RDP.default as any).default as any) ??
  (RDP.default as any) ??
  (RDP as any)) as typeof RDP.default;

export const OeCalendar = (props: OeCalendarProps) => {
  const [thisCalendarType, setCalendarType] = useState("Day");
  const [isOpen, setIsOpen] = useState(true);
  const [startDate, setStartDate] = useState(props.startDate ?? new Date(0));
  const [endDate, setEndDate] = useState(props.endDate ?? new Date());

  /*const [formattedDate, setFormattedDate] = useState(
    formatDate(startDate, endDate),
  );*/

  const themeContext = useThemeUI();
  const theme = themeContext.theme as OceanExplorerTheme;
  if (Object.getOwnPropertyNames(theme).length <= 0) {
    // Theme not initialized yet.
    return <></>;
  }

  function handleChange([start, end]: [Date, Date]) {
    const formattedDate = formatDate(start, end);
    setStartDate(start);
    setEndDate(end);

    if (props.onChange) {
      props.onChange({
        startDate: start,
        endDate: end,
        formattedDate: formattedDate,
      });
    }
  }

  function updateCalendar(type: string) {
    setCalendarType(type);
    if (props.onChange) {
      const formattedDate = formatDate(startDate, endDate);
      props.onChange({ startDate, endDate, formattedDate });
    }
  }

  function formatDate(
    start: Date | undefined,
    end: Date | undefined,
  ): string | undefined {
    if (!start) return; // no new date inputted to be formatted

    const options: Intl.DateTimeFormatOptions = {
      year:
        thisCalendarType === "Year" ||
        thisCalendarType === "Month" ||
        thisCalendarType === "Day"
          ? "numeric"
          : undefined,
      month:
        thisCalendarType === "Month" || thisCalendarType === "Day"
          ? "short"
          : undefined,
      day: thisCalendarType === "Day" ? "numeric" : undefined,
    };

    const formattedStartDate = start.toLocaleDateString("en-AU", options);
    const formattedEndDate: string | undefined = end?.toLocaleDateString(
      "en-AU",
      options,
    );

    const thisFormattedDate = formattedEndDate
      ? `${formattedStartDate} - ${formattedEndDate}`
      : formattedStartDate;
    return thisFormattedDate;
  }

  const buttonStyle: ThemeUIStyleObject = {
    width: "15%",
    textAlign: "center",
  };

  return (
    <Box sx={{ width: "90%" }}>
      <Label variant="muted" sx={{ color: theme.colors.muted }}>
        {" "}
        Time{" "}
      </Label>
      <Heading
        as="h3"
        variant={"styles.h3"}
        sx={{ marginTop: 4, marginBottom: 4 }}
      >
        {formatDate(startDate, endDate)}
      </Heading>
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/react-datepicker/6.2.0/react-datepicker.min.css"
      />
      <DatePickerStyle {...theme} />
      <Box
        variant="boxes.sectionRounded"
        sx={{ textAlign: "center", paddingTop: 3 }}
      >
        <ReactDatePicker
          selected={startDate}
          onChange={handleChange}
          selectsRange
          startDate={startDate}
          endDate={endDate}
          monthsShown={thisCalendarType === "Day" ? 2 : 1}
          inline
          minDate={props.minDate}
          maxDate={props.maxDate}
          openToDate={startDate}
          showYearPicker={thisCalendarType === "Year"}
          showMonthYearPicker={thisCalendarType === "Month"}
        />
      </Box>
    </Box>
  );
};

/*NOTE ABOUT BELOW: Newest version of Figma Docs (as of sprint 3) automatically opens to 'Day'
calendar mode and does not switch between states. Will add in below logic again if that design changes*/

/*
 {/*{!isOpen && (
          <Button
            variant="secondary"
            sx={{width: "30%"}}
            onClick={() => setIsOpen(true)}
          >
            <Box           sx={{
            width: "100%",
            paddingTop: 0,
            ...props.sx,
            display: "flex",
            justifyContent: "space-between",
           // marginBottom: "20px",
          }}>
            <OeIcon iconName="calendar"></OeIcon>
            <Label variant="primary">Calendar</Label>
            </Box>
          </Button>
        )}
        {isOpen && (
          <Box variant="boxes.sectionRounded" padding="10px">
            <Button
              variant={thisCalenderType === "Day" ? "primary" : "secondary"}
              sx={buttonStyle}
              onClick={() => updateCalendar("Day")}
            >
              Day
            </Button>
            <Button
              variant={thisCalenderType === "Month" ? "primary" : "secondary"}
              sx={buttonStyle}
              onClick={() => updateCalendar("Month")}
            >
              Month
            </Button>
            <Button
              variant={thisCalenderType === "Year" ? "primary" : "secondary"}
              sx={buttonStyle}
              onClick={() => updateCalendar("Year")}
            >
              Year
            </Button>
            <Button
              variant="secondary"
              sx={buttonStyle}
              onClick={() => setIsOpen(false)}
            >
              Done
            </Button>

        <Divider />
*/
