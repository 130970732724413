import * as base from "@theme-ui/preset-base";
import { hasIn, merge, omit } from "lodash";

const greyColor = "#1f2937";
// const colors = {
//   text: "#FFFFFF",
//   background: "#111827",
//   primary: "#3B82F6",
//   secondary: "#374151",
//   tertiary: "#374151",
//   muted: greyColor,
//   rendererBackground: greyColor,
//   link: "#5CB8E6",
//   linkVisited: "#D79CEC",
// };

const palette = {
  neutral: {
    v10: "#faf7ed",
    v20: "#f4f1e6",
    v30: "#e9e6dc",
    v40: "#c8c6be",
    v50: "#94938c",
    v60: "#64635f",
    v70: "#454541",
    v80: "#30302c",
    v90: "#181816",
    v100: "#000000",
  },
  accent: {
    red: "#ed3838",
  },
};

// We're not using this anywhere yet, but we _should_
const colorVariablesRenderer = {
  line: palette.neutral.v40,
};

const colorVariablesDark = {
  text: {
    primary: palette.neutral.v10,
    secondary: palette.neutral.v50,
    muted: palette.neutral.v50,
    disabled: palette.neutral.v70,
    inverse: palette.neutral.v100,
    error: palette.accent.red,
    button: {
      primary: {
        enabled: palette.neutral.v100,
        disabled: palette.neutral.v70,
      },
      secondary: {
        enabled: palette.neutral.v10,
        disabled: palette.neutral.v60,
      },
    },
  },
  background: {
    primary: palette.neutral.v100,
    secondary: palette.neutral.v90,
    input: {
      active: palette.neutral.v90,
      selected: palette.neutral.v80,
    },
    button: {
      primary: {
        enabled: palette.neutral.v10,
        disabled: palette.neutral.v100,
        hover: palette.neutral.v20,
      },
      secondary: {
        enabled: palette.neutral.v100,
        disabled: palette.neutral.v100,
      },
      toggle: {
        enabled: palette.neutral.v100,
        disabled: palette.neutral.v100,
        activated: palette.neutral.v10,
      },
    },
  },
  border: {
    section: palette.neutral.v80,
    input: {
      active: palette.neutral.v60,
      selected: palette.neutral.v10,
    },
    button: {
      primary: {
        enabled: palette.neutral.v100,
        disabled: palette.neutral.v30,
      },
      secondary: {
        enabled: palette.neutral.v60,
        disabled: palette.neutral.v100,
        hover: palette.neutral.v10,
      },
      toggle: {
        enabled: palette.neutral.v60,
        disabled: palette.neutral.v100,
        hover: palette.neutral.v10,
      },
    },
  },
  renderer: colorVariablesRenderer,
};

const colorVariablesLight: typeof colorVariablesDark = {
  text: {
    primary: palette.neutral.v100,
    secondary: palette.neutral.v60,
    muted: palette.neutral.v50,
    disabled: palette.neutral.v50,
    inverse: palette.neutral.v10,
    error: palette.accent.red,
    button: {
      primary: {
        enabled: palette.neutral.v10,
        disabled: palette.neutral.v40,
      },
      secondary: {
        enabled: palette.neutral.v100,
        disabled: palette.neutral.v40,
      },
    },
  },
  background: {
    primary: palette.neutral.v10,
    secondary: palette.neutral.v20,
    button: {
      primary: {
        enabled: palette.neutral.v100,
        disabled: palette.neutral.v30,
        hover: palette.neutral.v80,
      },
      secondary: {
        enabled: palette.neutral.v10,
        disabled: palette.neutral.v10,
      },
      toggle: {
        enabled: palette.neutral.v10,
        disabled: palette.neutral.v10,
        activated: palette.neutral.v100,
      },
    },
    input: {
      active: palette.neutral.v20,
      selected: palette.neutral.v30,
    },
  },
  border: {
    section: palette.neutral.v30,
    input: {
      active: palette.neutral.v40,
      selected: palette.neutral.v100,
    },
    button: {
      primary: {
        enabled: palette.neutral.v10,
        disabled: palette.neutral.v30,
      },
      secondary: {
        enabled: palette.neutral.v40,
        disabled: palette.neutral.v10,
        hover: palette.neutral.v50,
      },
      toggle: {
        enabled: palette.neutral.v10,
        disabled: palette.neutral.v10,
        hover: palette.neutral.v100,
      },
    },
  },
  renderer: colorVariablesRenderer,
};

const defaultFonts = {
  fontFamily: "body",
  fontWeight: "body",
  color: "vars.text.primary",
};

const removeUnsetVariables = (obj: any) => {
  const toOmit: string[] = [];
  for (const [key, variableOrObject] of Object.entries<any>(obj)) {
    if (
      typeof variableOrObject === "string" &&
      variableOrObject.startsWith("vars.")
    ) {
      const variable = variableOrObject.replace("vars.", "");
      if (!hasIn(colorVariablesLight, variable)) {
        toOmit.push(key);
      }
    } else if (typeof variableOrObject === "object") {
      obj[key] = removeUnsetVariables(variableOrObject);
    }
  }
  return omit(obj, toOmit);
};

const makeButtonVariant = (variantName: string, overrides?: any) => {
  return removeUnsetVariables(
    merge(
      {
        color: `vars.text.button.${variantName}.enabled`,
        bg: `vars.background.button.${variantName}.enabled`,
        borderRadius: 4,
        height: "40px",
        padding: [2, 3],
        borderColor: `vars.border.button.${variantName}.enabled`,
        borderWidth: 0,
        borderStyle: "solid",
        fontSize: 1,
        ":disabled": {
          color: `vars.text.button.${variantName}.disabled`,
          bg: `vars.background.button.${variantName}.disabled`,
          borderColor: `vars.border.button.${variantName}.disabled`,
        },
        ":hover": {
          cursor: "pointer",
          borderColor: `vars.border.button.${variantName}.hover`,
          backgroundColor: `vars.background.button.${variantName}.hover`,
        },
        ":disabled:hover": {
          cursor: "pointer",
          borderColor: `vars.border.button.${variantName}.disabled`,
          backgroundColor: `vars.background.button.${variantName}.disabled`,
        },
      },
      overrides,
    ),
  );
};

const boxSectionDefaults = {
  backgroundColor: "vars.background.primary",
  borderColor: "vars.border.section",
  borderWidth: 0,
  borderStyle: "solid",
};

const lightMode = {
  vars: colorVariablesLight,
  background: colorVariablesLight.background.primary,
  text: colorVariablesLight.text.primary,
  primary: colorVariablesLight.background.button.primary.enabled,
  secondary: colorVariablesLight.background.button.secondary.enabled,
  muted: palette.neutral.v50,
};
const darkMode = {
  vars: colorVariablesDark,
  background: colorVariablesDark.background.primary,
  text: colorVariablesDark.text.primary,
  primary: colorVariablesDark.background.button.primary.enabled,
  secondary: colorVariablesDark.background.button.secondary.enabled,
  muted: palette.neutral.v50,
};

const headingStyles = {
  hr: {
    borderColor: "vars.border.section",
  },
  h6: {
    variant: "text.heading",
    fontSize: 0,
  },
  h5: {
    variant: "text.heading",
    fontSize: 1,
  },
  h4: {
    variant: "text.heading",
    fontSize: 2,
  },
  h3: {
    variant: "text.heading",
    fontSize: 3,
  },
  h2: {
    variant: "text.heading",
    fontSize: 6,
  },
  h1: {
    variant: "text.heading",
    fontSize: 5,
  },
};

const labelStyleBase = {
  fontFamily: "heading",
  fontWeight: "light",
  // ThemeUI labels use flexbox, so this centers text vertically
  alignItems: "center",
};

export const makeTheme = (defaultColorMode: "light" | "dark" = "light") => {
  const defaultColorPreset =
    defaultColorMode === "light" ? lightMode : darkMode;

  return {
    ...base,
    workbenchWidthPercent: 20,
    fonts: {
      body: '"Inter", system-ui, sans-serif',
      heading: '"Inter", sans-serif',
      monospace: "Menlo, monospace",
    },
    radii: [2, 4, 8, 12, 24],
    borderWidths: [1, 2, 4],
    space: [0, 4, 8, 12, 16, 24, 32, 64, 96, 128, 256, 512],
    fontWeights: {
      body: 500,
      light: 400,
      heading: 500,
      bold: 700,
    },
    styles: {
      // Remember! These only affect MDX (except for root)
      root: defaultFonts,
      a: {
        color: "link",
        "&:visited": {
          color: "linkVisited",
        },
      },
      p: {
        marginY: "1em",
      },
      ...headingStyles,
    },
    links: {
      nav: defaultFonts,
    },
    text: {
      default: defaultFonts,
      muted: {
        ...defaultFonts,
        color: "vars.text.muted",
      },
      secondary: {
        ...defaultFonts,
        color: "vars.text.secondary",
      },
      ...headingStyles,
    },
    fontSizes: [12, 14, 16, 18, 20, 24, 28, 32, 48, 64, 96],
    config: {
      initialColorModeName: "default",
      useLocalStorage: false,
      useColorSchemeMediaQuery: false,
    },
    colors: {
      ...defaultColorPreset,
      modes: {
        dark: darkMode,
        light: lightMode,
      },
    },

    buttons: {
      primary: makeButtonVariant("primary"),
      secondary: makeButtonVariant("secondary"),
      menuItem: makeButtonVariant("secondary", {
        borderRadius: 0,
        border: "none",
      }),
    },

    images: {
      thumbnail: {
        borderRadius: 3,
        backgroundColor: "vars.background.secondary",
      },
      rounded: {
        borderRadius: 3,
      },
    },

    forms: {
      labels: {
        primary: {
          ...labelStyleBase,
          fontSize: 2,
        },
        secondary: {
          ...labelStyleBase,
          fontSize: 1,
        },
      },
    },

    cards: {
      primary: {
        padding: 4,
        margin: 4,
        borderRadius: 2,
        background: "vars.background.primary",
        borderWidth: 0,
        borderStyle: "solid",
        borderColor: "vars.border.section",
      },
    },

    panels: {
      primary: {
        header: {
          marginTop: 5,
          marginBottom: 5,
          marginLeft: 4,
          marginRight: 4,
        },
        body: {
          display: "flex",
          flexDirection: "column",
          margin: 5,
          gap: 4,
        },
      },
    },

    dropdowns: {
      primary: {
        backgroundColor: "vars.background.input.active",
        borderWidth: 0,
        borderColor: "vars.border.input.active",
        borderStyle: "solid",
        // TODO: write docs to explain
        // that no literal <option> component exists
        "> option": {
          borderStyle: "none",
          ":hover": {
            backgroundColor: "vars.background.input.selected",
            borderColor: "vars.border.input.selected",
          },
        },
      },
    },

    boxes: {
      section: boxSectionDefaults,
      sectionRounded: { ...boxSectionDefaults, borderRadius: 3 },
    },

    sliders: {
      primary: {
        "slider-bg": "vars.background.input.active",
        "slider-bg-fill": "vars.background.button.primary.enabled",
        "slider-bg-hover": "vars.background.input.active",
        "slider-bg-focus": "vars.background.input.active",
        "pointer-bg": "vars.background.button.primary.enabled",
        "pointer-bg-focus": "vars.background.button.primary.hover",
        "pointer-bg-hover": "vars.background.button.primary.hover",
      },
    },

    icons: {
      primary: {
        fill: "vars.text.muted",
      },
    },
  };
};

export const darkTheme = makeTheme("dark");
export const lightTheme = makeTheme("light");

export type OceanExplorerTheme = ReturnType<typeof makeTheme>;
