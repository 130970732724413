import L from "leaflet";
import { useEffect, useRef } from "react";
import type { OceanExplorerTheme } from "theme";
import { Box, Label, useThemeUI } from "theme-ui";
import type { OeComponentBase } from "./OeComponentBase";

export interface OeMapSelectProps extends OeComponentBase {
  labelText?: string;
  onChange?: (rect: L.LatLngBounds) => void;
}

export const OeMapSelect = (props: OeMapSelectProps) => {
  // Grab the theme
  const themeContext = useThemeUI();
  const theme = themeContext.theme as OceanExplorerTheme;
  if (Object.getOwnPropertyNames(theme).length <= 0) {
    // Theme not initialized yet.
    return <></>;
  }

  const mapContainer = useRef(null);

  useEffect(() => {
    if (mapContainer.current) {
      // initial view centered on melbourne :P
      const oe_map = L.map(mapContainer.current).setView([-37.81, 144.96], 4);
      oe_map.zoomControl.setPosition("bottomright");

      // the ESRI ocean base map gives a nice idea of bathy...
      L.tileLayer(
        "https://server.arcgisonline.com/ArcGIS/rest/services/Ocean/World_Ocean_Base/MapServer/tile/{z}/{y}/{x}",
        {
          attribution:
            "Tiles &copy; Esri &mdash; Sources: GEBCO, NOAA, CHS, OSU, UNH, CSUMB, National Geographic, DeLorme, NAVTEQ, and Esri",
        },
      ).addTo(oe_map);

      // ... or use the default openstreetmap
      // L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png").addTo(
      //   oe_map
      // );

      // set the initial rect to be some subset of the whole map bounds
      // for some reason, pad values of less than or equal to -0.5 set the
      // bounds to 0
      const rectSelect = new L.Rectangle(oe_map.getBounds().pad(-0.45));
      oe_map.addLayer(rectSelect);

      // fire off the onChange callback for the initial boundary
      if (props.onChange !== undefined) {
        props.onChange(rectSelect.getBounds());
      }

      // set a flag for when we are lassoing the rect
      let select = false;
      let start_pt = new L.LatLng(0.0, 0.0);

      // capture right clicks and toggle the "select" flag.
      // we'll track mousemove events if we're "select"ing,
      // otherwise if we've toggled off, emit the selected
      // rect with the caller's onChange()
      oe_map.on("contextmenu", function (event) {
        select = !select;
        if (select) {
          start_pt = event.latlng;
        } else if (props.onChange !== undefined) {
          props.onChange(rectSelect.getBounds());
        }
      });

      oe_map.on("mousemove", function (event) {
        if (select) {
          rectSelect.setBounds(L.latLngBounds(start_pt, event.latlng));
        }
        // else fall through to default leaflet drag behaviour
      });

      return () => {
        oe_map.remove();
      };
    }
  }, []);

  return (
    <Box
      ref={mapContainer}
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      <link
        rel="stylesheet"
        href="https://unpkg.com/leaflet@1.9.4/dist/leaflet.css"
      />
      {/* <link rel="stylesheet" href="~leaflet/dist/leaflet.css" />  */}
      {props.labelText !== undefined && (
        <Label sx={{ marginBottom: 3 }}>{props.labelText}</Label>
      )}
    </Box>
  );
};
