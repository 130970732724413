import type { Datasource, DatasourceList } from "oex-common-model";

export async function getDatasources(
  initialData?: string,
): Promise<Datasource[]> {
  if (initialData) {
    try {
      const parsedData = JSON.parse(initialData);
      console.log("Data parsed!");
      return parsedData;
    } catch (error) {
      console.error("Error: " + error);
      return [];
    }
  }

  const response = await fetch("/api/datasource");

  return ((await response.json()) as DatasourceList).datasources;
}
