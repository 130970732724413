export const stageNames = [
  "region",
  "datasets",
  "variables",
  "time",
  "submit",
  "done!",
] as const;
type stageTuple = typeof stageNames;
export type StageName = stageTuple[number];
