import { PageContainer } from "src/components/PageContainer";
import { Heading, Text, Link } from "theme-ui";

export const PricingPageRoute = "/home/pricing";
export const PricingPage = () => {
  return (
    <PageContainer>
      <Heading as="h1">
        <Text>Work with us</Text>
      </Heading>
    </PageContainer>
  );
};
