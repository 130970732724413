import { OeThumbnail } from "oex-common-ui";
import { PageContainer } from "src/components/PageContainer";
import { Flex, Heading, Text } from "theme-ui";
import { PublicDataRequestPageRoute } from "./PublicDataRequest/PublicDataRequestPage";

export const DataCataloguePageRoute = "/home/catalogue";

const PublicDataRequestHashLink = "#" + PublicDataRequestPageRoute;

export const DataCataloguePage = () => {
  return (
    <PageContainer>
      <Heading
        sx={{
          marginBottom: "24px",
        }}
      >
        <Text variant="muted">Data Catalogue</Text>
      </Heading>
      <Flex
        sx={{
          flexDirection: "row",
          gap: "24px",
          justifyContent: "start",
        }}
      >
        <OeThumbnail
          headingText="Explore public catalogue"
          text="Explore a curated catalogue of public data services"
          iconName="globe-plus"
          url={PublicDataRequestHashLink}
        />

        <OeThumbnail
          headingText="Request a new dataset"
          text="Let us know which datasets you want to see on the IDI platform"
          iconName="message-plus"
          url="https://forms.office.com/r/ZqerbDr45y"
        />
      </Flex>
    </PageContainer>
  );
};
