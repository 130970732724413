import { Box, Checkbox, Divider, Label, useThemeUI } from "theme-ui";
import type { OceanExplorerTheme } from "./theme";

export interface OptionObject<ValueType> {
  value: ValueType;
  id?: string;
  label?: string;
  sectionLabel?: string;
  element?: JSX.Element;
}

// See tutorials/type-guards.md for an explanation of type guards for generics.
// We're only using this type guard to distinguish between string options and
// object options, so we don't need to check the type parameter of OptionObject
function isOptionsObject(value: any): value is OptionObject<unknown> {
  if (typeof value !== "object") {
    return false;
  }
  return value?.value !== undefined;
}

function isArrayOfOptionsObjects<T>(value: any[]): value is OptionObject<T>[] {
  return value.every((el) => isOptionsObject(el));
}

const OeCheckboxSection = <
  ValueType,
  OptionType extends OptionObject<ValueType>,
>(props: {
  options: OptionType[] | string[];
  selected: string[];
  getIdForOption: (opt: OptionType | string) => string;
  getLabelForOption: (opt: OptionType | string) => string;
  sectionLabel?: string;
  onChange?: (evt: any, option: OptionType | string) => void;
}) => {
  const themeContext = useThemeUI();
  const theme = themeContext.theme as OceanExplorerTheme;
  if (Object.getOwnPropertyNames(theme).length <= 0) {
    // Theme not initialized yet.
    return <></>;
  }

  const options = props.options;
  const selected = props.selected;

  const SectionLabel = (sectionProps: { text?: string }) => {
    if (sectionProps.text !== undefined) {
      return (
        <>
          <Label variant="primary" sx={{ color: theme.colors.muted }}>
            {sectionProps.text}
          </Label>
          <Divider sx={{ marginBottom: "16px" }} />
        </>
      );
    }
    return <></>;
  };

  const OptionElement = (props: { option: OptionType | string }) => {
    if (
      typeof props.option === "string" ||
      props.option.element === undefined
    ) {
      return <></>;
    }
    return props.option.element;
  };

  const handleChange =
    props.onChange ?? ((_evt: any, _opt: OptionType | string) => {});

  // Get the id for each option
  const optionIds = options.map<[string, OptionType | string]>((opt) => [
    props.getIdForOption(opt),
    opt,
  ]);
  // Make a checkbox for each option
  const OptionCheckboxes = optionIds.map(([id, opt]) => (
    <Box key={id} sx={{ paddingBottom: 2 }}>
      <Label
        htmlFor={`checkbox-${id}`}
        variant="primary"
        sx={{ marginBottom: 2 }}
      >
        <Checkbox
          variant="primary"
          id={`checkbox-${id}`}
          checked={selected.some(
            // This option is one of the selected options
            (selectedId) => selectedId === id,
          )}
          sx={{ paddingBottom: 1 }}
          onChange={(evt: any) => handleChange(evt, opt)}
        />
        {props.getLabelForOption(opt)}
      </Label>
      <OptionElement option={opt} />
    </Box>
  ));

  return (
    <Box sx={{ paddingBottom: "24px" }}>
      <SectionLabel text={props.sectionLabel} />
      <Box>
        <ul
          style={{
            padding: 0,
            margin: 0,
            paddingLeft: 0,
          }}
        >
          {...OptionCheckboxes}
        </ul>
      </Box>
    </Box>
  );
};

export interface OeCheckboxListProps<
  ValueType,
  OptionType extends OptionObject<ValueType>,
> {
  options: OptionType[] | string[];
  selected: string[];
  onChange?: (selectionChange: [string, boolean]) => void;
}

export const OeCheckboxList = <
  OptionType extends OptionObject<ValueType>,
  ValueType,
>(
  props: OeCheckboxListProps<ValueType, OptionType>,
) => {
  const options = props.options;
  const selected = props.selected;
  // // We keep a local state in case the parent component
  // const [selected, setSelected] = useState(props.selected);
  // const [options, setOptions] = useState(props.options);

  // if (props.selected !== selected) {
  //   setSelected(props.selected);
  // }
  // if (props.options !== options) {
  //   setOptions(props.options);
  // }

  const getIdForOption = (opt: OptionType | string): string => {
    if (typeof opt === "string") {
      return opt;
    }
    return opt.id ?? opt.label ?? `${opt.value}`;
  };

  const getLabelForOption = (opt: OptionType | string): string => {
    if (typeof opt === "string") {
      return opt;
    }
    return opt.label ?? `${opt.value}`;
  };

  const handleChange = (evt: any, option: any) => {
    if (props.onChange === undefined) {
      return;
    }

    const optionId = getIdForOption(option);
    props.onChange([optionId, evt.target.checked]);
  };

  // We use the type guard we defined earlier to narrow down what kind of
  // options
  // we're dealing with. Not only is it useful for program logic, it also tells
  // the ts compiler that after this if statement, `options` is definitely an array of
  // objects, not strings
  if (!isArrayOfOptionsObjects(options)) {
    // options are simple strings
    return (
      <OeCheckboxSection
        options={props.options as string[]}
        selected={selected}
        onChange={handleChange}
        getIdForOption={getIdForOption}
        getLabelForOption={getLabelForOption}
      />
    );
  }

  const sectionLabels = [
    ...new Set(
      options
        .map((opt) => opt.sectionLabel)
        .filter((label) => label !== undefined),
    ),
  ];

  // If we don't have any section labels, or we only have one section, don't
  // use labels
  const useLabels = sectionLabels.length > 1;

  const sections = sectionLabels?.map((label) =>
    options.filter((opt) => opt.sectionLabel === label),
  ) ?? [options];

  // `selected` could contain ids from another section, but these will be ignored
  return (
    <>
      {...sections.map((s, idx) => (
        <OeCheckboxSection
          key={idx}
          options={s}
          sectionLabel={useLabels ? s[0]?.sectionLabel : undefined}
          selected={selected}
          getIdForOption={getIdForOption}
          getLabelForOption={getLabelForOption}
          onChange={handleChange}
        />
      ))}
    </>
  );
};
