import type { Datasource, Variable } from "oex-common-model";
import { useState } from "react";
import { getDatasources } from "./getDatasources";

// TLDR: We made these custom React hooks and put them in a different file to make
// the code easier to read. Otherwise, PublicDataRequestPage would be long and
// hard to read.
//
// In this file, we're defining a bunch of custom hooks.
// Strictly speaking, we don't _need_ to do this. The body of each of these
// functions could be copied unchanged inside the PublicDataRequestPage JSX
// component, and most of them will only be called once.
// But then the PublicDataRequestPage will be really long, and hard to read.
// As a rule of thumb, if a file hits 500+ lines, it's better to split it up.
//
// At the time of writing (16 Jul 2024) the [docs](https://react.dev/learn/reusing-logic-with-custom-hooks)
// for custom hooks are pretty confusing.
//
// Almost all the examples
// listed use `useEffect` which, although correct in the cases provided, is
// unusual in practice. They don't mentioned this until a long way down the page.
// Remember, `useEffect` is only needed for side effects - responding to state
// changes from _outside_ the reactive context, like network events.

// ---

// Always provide as much type information as you can. You could use a regular
// array for this data like: [1, 2, 3, 4], but defining an interface has some
// benefits:
// - The typescript compiler can tell you if there are too many values eg.
//   [1, 2, 3, 4, 5]
// - It makes it clearer what each number is for - coordinates.north is more
//   informative than coordinates[0]
// - The order of values doesn't matter. Making them named object properties lets us define them in
//   any order
export interface Coordinates {
  north: number;
  west: number;
  south: number;
  east: number;
}
export const useCoordinates = (): [
  Coordinates,
  (coordinateUpdate: Partial<Coordinates>) => void,
] => {
  // If we don't provide a type argument to `useState`, Typescript will infer
  // the type of its arguments. We know that the state conforms to the Coordinates
  // interface.
  const [coordinates, _setCoordinates] = useState<Coordinates>({
    // 148.438683,-30.521456,157.029991,-20.403203
    north: -30, // These initial values define a bounding box off the north coast of nsw/south east queensland
    west: 148,
    east: 157,
    south: -120,
  });

  // Not all coordinate values will be updated every time — the `Partial`
  // type indicates that unlike in the normal Coordinates interface, some
  // properties may be undefined
  const updateCoordinates = (coordinateUpdate: Partial<Coordinates>) => {
    _setCoordinates({ ...coordinates, ...coordinateUpdate });
  };

  return [coordinates, updateCoordinates];
};

/**
 * Checks whether `coordinates` is a valid bounding box
 * @param coordinates
 * @returns true if valid, false if invalid
 */
export const validateCoordinates = (coordinates: Coordinates) => {
  for (const coordinate of Object.values(coordinates)) {
    if (isNaN(Number(coordinate)) || coordinate === "") {
      return false;
    }
    if (coordinates.west > coordinates.east) {
      return false;
    }
    // we're not checking latitude, (yet), because whether north < south or
    // south < north depends on the hemisphere so it's more complicated
  }
  return true;
};

export const useVariables = () => useState<Variable[]>([]);

export const useDate = () => {
  const defaultStartDate = new Date("2020/05/01");
  const defaultEndDate = new Date("2020/05/31");
  const defaultSelectedDates = "1 May 2020 - 31 May 2020";

  const [startDate, setStartDate] = useState<Date>(defaultStartDate);
  const [endDate, setEndDate] = useState<Date>(defaultEndDate);
  const [selectedDates, _setSelectedDates] =
    useState<string>(defaultSelectedDates);

  const setSelectedDates = (val?: string) => {
    _setSelectedDates(val ?? defaultSelectedDates);
  };

  const setStartAndEndDates = (start?: Date, end?: Date) => {
    setStartDate(start ?? defaultStartDate);
    setEndDate(end ?? defaultEndDate);
  };

  return {
    selectedDates,
    startDate,
    endDate,
    setSelectedDates,
    setStartAndEndDates,
  };
};

export const useDatasources = (coords: Coordinates) => {
  const [_dataSources, _setDatasources] = useState<Datasource[]>([]);
  const [coordinates, _setDatasourceCoordinates] =
    useState<Coordinates>(coords);
  const [selectedDatasourceIds, setSelectedDatasourceIds] = useState<string[]>(
    [],
  );

  // These are recalculated every time this function is called (every rerender)
  // That way, we're not storing multiple copies of each Dataset, and `_dataSources` is the single source of truth
  // We could cache them for performance reasons, but it's not a big deal for now
  let validDatasources: Datasource[] = [];
  let invalidDatasources: Datasource[] = [];

  const datasourceIsValid = (ds: Datasource) => {
    // const datasourceBounds = ds.bounds;
    // // TODO: Right now, we can only handle cases that don't cross the international date line
    // // The way map works means that if you put area on right side of date line (i.e. right side of new zealand) it returns as
    // // invalid. This is bc rather than updating to (e.g.) 1, it will return -181.
    // EJK: This doesn't work and I didn't have time to fix it. Sorry :C
    // const coordinatesValid =
    //   coordinates.west < coordinates.east &&
    //   coordinates.south < coordinates.north;
    // const xInRange =
    //   datasourceBounds.x.min < coordinates.west &&
    //   coordinates.east < datasourceBounds.x.max;
    // const yInRange =
    //   datasourceBounds.y.min < coordinates.south &&
    //   coordinates.north < datasourceBounds.y.max;
    // return coordinatesValid && xInRange && yInRange;
    return true;
  };

  if (_dataSources === undefined || _dataSources.length === 0) {
    // We don't have any data! Fetch some.
    getDatasources().then((dss) => {
      // Remember, all react state variables should be treated as immutable,
      // including arrays.
      // https://react.dev/learn/updating-arrays-in-state
      _setDatasources(dss);
      // our previous selected datasource might no longer be valid, so reset it
      setSelectedDatasourceIds([dss[0]?.resourceId]);
    });
  }

  if (_dataSources !== undefined) {
    validDatasources = _dataSources.filter(datasourceIsValid);
    invalidDatasources = _dataSources.filter((ds) => !datasourceIsValid(ds));
  }

  const setDatasourceCoordinates = (
    // Not all coordinate values will be updated every time
    updateCoordinates: Partial<Coordinates>,
  ) => {
    setDatasourceCoordinates({ ...coordinates, ...updateCoordinates });
  };

  return {
    coordinates,
    setDatasourceCoordinates,
    setSelectedDatasourceIds,
    selectedDatasourceIds,
    validDatasources,
    invalidDatasources,
  };
};
