import { PageContainer } from "src/components/PageContainer";
import {
  Heading,
  Text,
  Link as ThemeUILink,
  Box,
  Button,
  useThemeUI,
} from "theme-ui";
import { LandingPageRoute } from "./LandingPage";
import { Link as HashLink } from "react-router-dom";
import { PrivacyPageRoute } from "./PrivacyPage";
import type { OceanExplorerTheme } from "oex-common-ui";

export const ContactPageRoute = "/home/contact";
export const ContactPage = () => {
  const themeContext = useThemeUI();
  const theme = themeContext.theme as OceanExplorerTheme;

  return (
    <PageContainer>
      <Heading as="h2" variant={"styles.h2"}>
        Contact
      </Heading>
      <Text as="p" variant={"styles.p"} sx={{ fontSize: 4 }}>
        <Text variant="muted">
          Immersive Data Insights (IDI) is an initiative of
        </Text>{" "}
        <Text> CSIRO&apos;s Data61</Text>
        <Text variant="muted">
          . Please get in touch with any questions, comments, feedback, or just
          to say hello 👋
        </Text>
      </Text>

      <Text as="p" variant="muted" sx={{ fontSize: 4 }}>
        Email
      </Text>

      <Text as="p" variant={"styles.p"} sx={{ fontSize: 3 }}>
        <ThemeUILink
          sx={{
            color: theme.colors.primary,
          }}
          href="mailto:immersivedata@internal.csiro.au"
        >
          immersivedata@internal.csiro.au
        </ThemeUILink>
      </Text>
      <Text as="p" variant={"styles.p"}>
        Information on how we handle your information can be found in our{" "}
        <HashLink to={PrivacyPageRoute}>privacy policy</HashLink>.
      </Text>
      <Box sx={{ marginTop: "32px" }}>
        <HashLink to={LandingPageRoute}>
          <Button variant="secondary">← Return to home</Button>
        </HashLink>
      </Box>
    </PageContainer>
  );
};
