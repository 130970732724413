import { OeMapSelect, OePanel } from "oex-common-ui";
import { useState } from "react";
import { HeaderBar } from "src/components/HeaderBar";
import { Box, Divider, Flex, Grid, Heading } from "theme-ui";
import { ControlPanelBody } from "./ControlPanelBody";
import {
  useCoordinates,
  useDatasources,
  useDate,
  useVariables,
} from "./PublicDataRequestState";
import type { StageName } from "./stageNames";
import { stageNames } from "./stageNames";

export const PublicDataRequestPageRoute = "/home/public-data-request";
export const PublicDataRequestPage = () => {
  const [currentStage, setStage] = useState<StageName>("region");
  const [stageCompletion, setStageCompletion] = useState<
    Record<StageName, boolean>
  >({
    region: false,
    datasets: false,
    variables: false,
    time: false,
    submit: false,
  });

  const [coordinates, updateCoordinates] = useCoordinates();
  const {
    setDatasourceCoordinates,
    setSelectedDatasourceIds,
    selectedDatasourceIds,
    validDatasources,
    invalidDatasources,
  } = useDatasources(coordinates);
  const [selectedVariables, setSelectedVariables] = useVariables();
  const {
    selectedDates,
    startDate,
    endDate,
    setStartAndEndDates,
    setSelectedDates,
  } = useDate();

  const handleMapChange = (rect: L.LatLngBounds) => {
    if (currentStage === "region") {
      const to3DecimalPlaces = (val: number) => parseFloat(val.toFixed(3));
      const newCoordinates = { ...coordinates };

      newCoordinates.north = to3DecimalPlaces(rect.getNorth());
      newCoordinates.west = to3DecimalPlaces(rect.getWest());
      newCoordinates.south = to3DecimalPlaces(rect.getSouth());
      newCoordinates.east = to3DecimalPlaces(rect.getEast());
      updateCoordinates(newCoordinates);
    }
  };

  // Not every stage has a panel
  //TEMP: Add this back in once we have map, removing for moment so we can add in coordinates manually
  //const hasControlPanel = (stageName: StageName) => stageName !== "region";
  const hasControlPanel = (stageName: StageName) => stageName;

  const isRegionStage = (stageName: StageName) => stageName === "region";

  const baseStyle = { cursor: "pointer" };
  const mutedColor = { ...baseStyle, color: "vars.text.muted" };

  const StageHeading = (headingProps: {
    stageName: StageName;
    index: number;
  }) => {
    const stageName = headingProps.stageName;

    const canNavigateToStage = (stageName: StageName) => {
      const currentStageIndex = stageNames.indexOf(currentStage);
      const targetStageIndex = stageNames.indexOf(stageName);
      return (
        stageCompletion[stageName] || targetStageIndex <= currentStageIndex
      );
    };

    return (
      <Heading
        variant="h1"
        sx={currentStage === stageName ? baseStyle : mutedColor}
        onClick={() => canNavigateToStage(stageName) && setStage(stageName)}
      >
        {`${
          headingProps.index + 1
        }. ${stageName[0].toLocaleUpperCase()}${stageName.slice(1)}`}
      </Heading>
    );
  };

  return (
    <Grid
      columns={["100%"]}
      gap={0}
      sx={{
        minHeight: "800px",
        height: "100%",
        gridTemplateRows: "fit-content(100%)",
      }}
    >
      <HeaderBar>
        <Flex sx={{ alignItems: "flex-start", gap: 5 }}>
          {stageNames.map((n, idx) => (
            // You can't access the key attribute within the component, (it's not a prop) so we
            // have to use a separate index prop
            <StageHeading key={idx} index={idx} stageName={n} />
          ))}
        </Flex>
      </HeaderBar>
      <Box
        sx={{
          position: "relative",
        }}
      >
        <OeMapSelect onChange={handleMapChange} />
        {/* transparent overlay if we're not in the region state ...
            the zIndex is set to 450 to lie in between the leaflet
            map (default zIndex 400) and the control panel we set to
            zIndex 500. This is a bit of a cludge to capture mouse
            clicks when we're not in the region select stage. */}
        {!isRegionStage(currentStage) && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              height: "100%",
              width: "100%",
              background: "rgba(0,0,0,0.2)",
              zIndex: 450,
            }}
          />
        )}
        {hasControlPanel(currentStage) && (
          <OePanel
            sx={{
              position: "absolute",
              top: 5,
              left: 5,
              width: currentStage === "time" ? "660px" : "524px",
              zIndex: 500,
            }}
            heading={
              currentStage[0].toLocaleUpperCase() + currentStage.slice(1)
            }
          >
            <ControlPanelBody
              stageName={currentStage}
              setStage={setStage}
              coordinates={coordinates}
              onCoordinateChange={updateCoordinates}
              selectedDatasources={selectedDatasourceIds}
              validDatasources={validDatasources}
              invalidDatasources={invalidDatasources}
              onDatasourceSelectionChange={([resourceId, selected]) => {
                setSelectedDatasourceIds(
                  validDatasources
                    .filter((ds) => {
                      {
                        if (ds.resourceId === resourceId) {
                          return selected;
                        }
                        if (selectedDatasourceIds.includes(ds.resourceId)) {
                          return true;
                        }
                      }
                    })
                    .map((ds) => ds.resourceId),
                );
              }}
              onVariableSelectionChange={([variable, selected]) => {
                let newSelection = [...selectedVariables];
                if (selected) {
                  newSelection.push(variable);
                } else {
                  newSelection = newSelection.filter(
                    (v) => v.resourceId !== variable.resourceId,
                  );
                }
                setSelectedVariables(Array.from(new Set(newSelection)));
              }}
              selectedVariables={selectedVariables.map((v) => v.resourceId)}
              onDateChange={({
                startDate,
                endDate,
                formattedDate,
              }: {
                startDate?: Date;
                endDate?: Date;
                formattedDate?: string;
              }) => {
                setStartAndEndDates(startDate, endDate);
                setSelectedDates(formattedDate);
              }}
              startDate={startDate}
              endDate={endDate}
              selectedDates={selectedDates}
            />
          </OePanel>
        )}
      </Box>
    </Grid>
  );
};
