import { Button } from "theme-ui";

export interface OeEmailButtonProps {
  dataset: string[] | null;
  coordinates: number[] | undefined[];
  variables: any[];
  dates: string;
  email: string;
}

export const OeEmailButton = (props: OeEmailButtonProps) => {
  function sendEmail() {
    const subject = `
  TO COMPLETE YOUR DATA REQUEST, PLEASE SEND THIS EMAIL WITH THE METADATA PROVIDED BELOW.

  For more information on our privacy policy, see https://fishtank.space/#/home/privacy


  -

  IDI Data Request - ${Date()}

  -

  Data Service:
  ${props.dataset}

  Region:
  Point A: [${props.coordinates[0]}, ${props.coordinates[1]}]
  Point B: [${props.coordinates[2]}, ${props.coordinates[3]}]

  Variables:
  ${props.variables}

  Time Range:
  ${props.dates}

  -

  Thank you!
  `;

    const encodedDataRequestDateString = encodeURIComponent(subject);

    window.location.href =
      "mailto:immersivedata@csiro.au?subject=IDI Data Request&body=" +
      encodedDataRequestDateString;
  }

  function validateEmail() {
    const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    return reg.test(props.email);
  }

  return (
    <Button
      variant="primary"
      sx={{ borderRadius: 2, height: "55px" }}
      onClick={sendEmail}
      disabled={!validateEmail()}
    >
      Submit Request
    </Button>
  );
};
