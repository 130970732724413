import type { ReactNode } from "react";
import { Link as HashLink } from "react-router-dom";
import {
  Box,
  Flex,
  Grid,
  Heading,
  Link as ThemeUILink,
  Paragraph,
  Text,
  useThemeUI,
} from "theme-ui";
import { LandingPageRoute } from "src/pages/LandingPage";
import { PricingPageRoute } from "src/pages/PricingPage";
import { OceanExplorerTheme } from "oex-common-ui";

interface PageContainerProps {
  children: ReactNode;
}

export const PageContainer = (props: PageContainerProps) => {
  const themeContext = useThemeUI();
  const theme = themeContext.theme as OceanExplorerTheme;

  return (
    <>
      <Box
        sx={{
          width: "100%",
          top: 0,
          padding: "32px",
          marginBottom: "32px",
          borderBottomColor: "vars.border.section",
          borderBottomStyle: "solid",
          borderBottomWidth: 0,
        }}
      >
        <Heading as="h1" variant={"styles.h1"}>
          <Flex
            sx={{
              justifyContent: "flex-start",
            }}
          >
            <HashLink
              to={LandingPageRoute}
              style={{
                textDecoration: "none",
              }}
            >
              <Text>Immersive Data Insights (IDI)</Text>
            </HashLink>

            <ThemeUILink
              sx={{ marginLeft: "0.5em" }}
              href="https://www.productplan.com/glossary/beta-test/"
            >
              Beta
            </ThemeUILink>

            <HashLink
              to={PricingPageRoute}
              style={{
                marginLeft: "auto",
                textDecoration: "none",
                color: theme.colors.primary,
              }}
            >
              <Text>Work with us</Text>
            </HashLink>
          </Flex>
        </Heading>
      </Box>

      <Box
        sx={{
          maxWidth: "1320px",
          marginLeft: "auto",
          marginRight: "auto",
          paddingY: "40px",
          paddingX: "40px",
        }}
      >
        {props.children}
      </Box>

      <Flex
        sx={{
          flexDirection: "column",
          justifyContent: "space-around",

          textAlign: "left",
          padding: "32px",

          borderTopColor: "vars.border.section",
          borderTopStyle: "solid",
          borderTopWidth: 0,

          marginTop: "32px",
          marginBottom: "32px",
        }}
      >
        <Heading
          as="h3"
          sx={{
            paddingY: "12px",
          }}
        >
          Immersive Data Insights (IDI)
        </Heading>

        <Flex
          sx={{
            alignContent: "flex-end",
            flexDirection: "row",
          }}
        >
          <Box sx={{ flex: 1 }}>
            <Paragraph>
              <Text variant="muted">CSIRO&apos;s Data61</Text>
            </Paragraph>

            <Paragraph>
              <Text variant="muted" sx={{ fontWeight: "light" }}>
                Sydney + Melbourne, Australia
              </Text>
            </Paragraph>
          </Box>

          <ThemeUILink
            sx={{
              flex: 2,
              alignSelf: "flex-end",
              color: theme.colors.primary,
              textDecoration: "none",
            }}
            href="mailto:immersivedata@internal.csiro.au"
          >
            immersivedata@internal.csiro.au
          </ThemeUILink>
        </Flex>
      </Flex>
    </>
  );
};
