import type { ReactNode } from "react";
import { Box, Heading } from "theme-ui";

interface HeaderBarProps {
  children?: ReactNode;
}
export const HeaderBar = (props: HeaderBarProps) => {
  return (
    <Box
      sx={{
        width: "100%",
        top: 0,
        backgroundColor: "background",
        alignItems: "center",
        padding: "32px",
        borderBottomColor: "vars.border.section",
        borderBottomStyle: "solid",
        borderBottomWidth: 0,
      }}
    >
      {/* Use a default heading if there's no content provided in the props */}
      {props.children ?? (
        <Heading variant="h1">Immersive Data Insights</Heading>
      )}
    </Box>
  );
};
