import { PageContainer } from "src/components/PageContainer";
import { Heading, Link } from "theme-ui";

export const PrivacyPageRoute = "/home/privacy";
export const PrivacyPage = () => {
  return (
    <PageContainer>
      <Heading as="h2">Privacy Notice</Heading>
      <p>
        Your personal information is protected by the Privacy Act 1988 (Cth)
        (Privacy Act). CSIRO will handle your information in accordance with
        this Act and the latest National Health & Medical Research Council
        National Statement on Ethical Conduct in Human Research or as otherwise
        required by law.
      </p>
      <p>
        Your personal information, including your name and email address, is
        being collected for the purposes of the Immersive Data Insights (IDI)
        project, and related scientific research.
      </p>
      <p>
        CSIRO does not intend to disclose your personal information overseas.
        However, some incidental overseas disclosure may occur in the conduct of
        an interview or in ordinary correspondence, particularly where some
        participants are located overseas, or where CSIRO engages third-party
        products or platforms to facilitate the project. This project uses
        Google Cloud, which is based in the United Stated (US) and uses servers
        based in the United States (US). This means your personal information
        may be transferred to servers located outside Australia.
      </p>
      <p>
        By participating in the project, you consent to the transfer of your
        personal information to servers located outside of Australia and you
        acknowledge that this information may not be subject to the requirements
        of the Privacy Act. If you have any concerns about this, you should not
        participate in the project.
      </p>
      <p>
        If you do not provide any or all of the personal information requested
        of you during the project, you may be unable to participate. If this
        occurs, it will not affect your ongoing relationship with CSIRO in any
        way.
      </p>
      <p>
        You are free to withdraw at any time during the project. Unless
        requested, we will retain any personal information collected prior to
        your withdrawal from the study. If you request that we delete your
        personal information, we will do so if permitted by and in accordance
        with any applicable laws (including the Privacy Act and the Archives Act
        1983 (Cth)).
      </p>
      <p>
        For further information on how CSIRO handles your personal information
        and our access, correction and complaints process please{" "}
        <Link
          href="https://www.csiro.au/en/about/policies/privacy"
          target="_blank"
        >
          read our privacy policy available on our website
        </Link>{" "}
        or by contacting us at{" "}
        <Link href="mailto:privacy@csiro.au">privacy@csiro.au</Link>.
      </p>
      <p>
        For information about how Google Cloud generally handle personal
        information, please refer to their privacy policies available at:
      </p>
      <ul>
        <li>
          <Link
            href="https://cloud.google.com/terms/cloud-privacy-notice"
            target="_blank"
          >
            https://cloud.google.com/terms/cloud-privacy-notice
          </Link>
        </li>
      </ul>
    </PageContainer>
  );
};
