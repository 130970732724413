import { lightTheme } from "oex-common-ui";

// Only put styles specific to oex-home in here.
export const homeTheme = {
  ...lightTheme,
  page: {
    withMargins: {
      margin: "72px 140px",
      height: "100%",
    },
    noMargins: {
      margin: "0",
      height: "100%",
    },
  },
};
